import React, { useState, useRef, useEffect } from "react";
import AvrgirlArduino from "avrgirl-arduino";

function App() {


  const boardChoices = [
    { name: "Arduino Nano",                       value: "nano"  },
    { name: "Arduino Nano (with new bootloader)", value: "nano (new bootloader)"}
  ];

  const fileInput = useRef(null);
  const [board, updateBoard] = useState(boardChoices[0].value);
  const [fileName, updateFileName] = useState("");
  const [uploadStatus, updateUploadStatus] = useState("");
  const [uploadStatusTitle, updateUploadStatusTitle] = useState("");
  const [uploadStatusMsg, updateUploadStatusMsg] = useState("");
  const [browserSupported, updateBrowserSupported] = useState(true);
  const [infoBoardSelectHover, setInfoBoardSelectHover] = useState(false);



  useEffect(() => {
    updateBrowserSupported('serial' in navigator);
  }, [])



  const handleSubmit = async e => {
    e.preventDefault();
    updateUploadStatus("flashing");
    updateUploadStatusTitle("Flashing MCU...");

    const file = await fetch("/deej/deej-firmware.ino.hex")
      .then(response => response.blob())
      .then(blob => blob);


    const reader = new FileReader();
    //Read file from public folder
    reader.readAsArrayBuffer(file);
    

    reader.onload = event => {
      const filecontents = event.target.result;
      
      const avrgirl = new AvrgirlArduino({
        board: board,
        debug: true
      });

      avrgirl.flash(filecontents, error => {
        if (error) {
          updateUploadStatus("error");
          updateUploadStatusTitle("Error Flashing MCU!");
          updateUploadStatusMsg(error.message)

        } else {
          console.info("flash successful");
          updateUploadStatus("done");
          updateUploadStatusTitle("Flash Sucessful!");
          updateUploadStatusMsg("Successfully flashed the MCU!");
        }
      });
    };
  };

  const clearStatus = () => {
    updateUploadStatus("");
    updateUploadStatusTitle("");
    updateUploadStatusMsg("")
  }

  const clearFileInput = () => {
    updateFileName("");
    if (fileInput) fileInput.current.value = null;
  }

  const BoardOptions = boardChoices.map((board, i) => <option value={board.value} key={i}>{board.name} {board.tested ? '✅' : ''}</option>)


  return (
    <div className="w-full h-screen bg-black">
      {!browserSupported && <div className="w-screen h-screen bg-opacity-90 fixed bg-black z-20 flex flex-col gap-2 justify-center items-center text-white text-lg text-center">
        <h2 className="text-white text-4xl mb-6 font-bold">Browser is not supported😥</h2>
        <p>Sorry, <b>Web Serial</b> is not supported on this browser.</p>
        <p>If you are on computer, please use Chrome or Opera</p>
        <p >If you are using Chrome, please make sure you're running Chrome 78 or later <br /> and have enabled the
          <code>#enable-experimental-web-platform-features</code> flag in
          <code>chrome://flags</code>.</p>
        <p> Mobile browsers are also not supported, check <a className="underline text-blue-400" href="https://caniuse.com/web-serial">Can I Use WebSerial</a> for compatibility.</p>
      </div>}
      {uploadStatus && <div className="w-screen h-screen bg-opacity-90 fixed bg-black z-20 flex flex-col justify-center items-center">
        {uploadStatus === 'flashing' && <span className="loader"></span>}
        {uploadStatus === 'done' && <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-14 h-14 text-green-400">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        </span>}
        <h2 className="text-white text-2xl mt-6">{uploadStatusTitle}</h2>
        <p className="text-white mt-2 w-1/2 text-center">{uploadStatusMsg}</p>
        {uploadStatusMsg && <button className="bg-cyan-500 py-1.5 px-4 hover:bg-cyan-600 rounded-md text-white mt-6" onClick={clearStatus}>Close</button>}
      </div>}
      <form id="uploadForm" onSubmit={handleSubmit} className="flex flex-col w-4/5 sm:md-2/3 md:w-1/3 lg:w-1/4 m-auto h-2/3 justify-evenly items-center">
        <div>
          <h1 className="text-center text-white text-4xl font-DotMatrix mb-12">DotMatrixLabs - <br/>MCU flasher</h1>
          <p className="text-center text-white text-xs pt-12 font-DotMatrix w-full m-auto">
            Check out <a className="underline text-blue-400" href="https://docs.dotmatrixlabs.com/dot-x/">docs</a> for more info:)
          </p>
        </div>

        <div className="w-2/3">
          <div className="flex flex-row justify-between relative">
            <label htmlFor="board-select" className="block mb-2 mt-8 text-sm font-medium text-gray-900 dark:text-gray-400">Select an option</label>
            <button
              type="button"
              className="mt-6"
              onMouseEnter={() => setInfoBoardSelectHover(true)}
              onMouseLeave={() => setInfoBoardSelectHover(false)}
            >
              <i className="text-white">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
              </i>
            </button>
            {infoBoardSelectHover && <span htmlFor="board-select" className="text-center w-3/4 text-xs text-gray-200 p-2 bg-black absolute right-10 rounded-lg transition-all opacity-90">If the flashing fails with the normal bootloader, please try again with the new bootloader</span>}

          </div>


          <select
            id="boardType"
            className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg hover:border-gray-400 focus:ring-blue-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
            value={board}
            onChange={event => updateBoard(event.target.value)}
          >
            {BoardOptions}
          </select>
        </div>

        <button type="submit" className="mt-4 relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-purple-500 group-hover:from-cyan-500 group-hover:to-purple-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800 disabled:bg-gradient-to-br disabled:from-gray-500 disabled:to-gray-600 disabled:text-gray-500">
          <span className="relative px-8 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-black rounded-md group-hover:bg-opacity-0">
            Flash!
          </span>
        </button>
      </form>
      <footer className="absolute bottom-2 text-gray-500 left-1/2 transform -translate-x-1/2 flex flex-col text-xs justify-center text-center">
        <span>Any issues? Please report them on <a className="underline text-blue-400" href="https://discord.gg/Y3GPMTq6UF">Discord</a></span>
      </footer>
    </div>
  );
}

export default App;
